import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'
import { Store } from '@ngxs/store'
import { OrderService } from 'src/app/shared/services/order.service'
import { environment } from 'src/environments/environment'
import { Utils } from 'src/helpers/utils.helper'
import {
  IProposal,
  IProposalStatus,
} from 'src/interfaces/proposal/proposal.interface'
import {
  ITableAction,
  ITableActionIcon,
  ITableBadge,
  ITableHeader,
} from 'src/interfaces/table/table.interface'
import { ResetQuoteStateAction } from 'src/store/quote/quote.meta.actions'
import { UserMetaState } from 'src/store/user/user.meta.state'
import { TableComponent } from '../../../../shared/components/table/table.component'

@Component({
  standalone: true,
  imports: [TableComponent],
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss'],
})
export class RequestListComponent implements OnInit, OnChanges {
  @Input() proposals: IProposal[] = []
  @Input() selectedCheckboxes = false
  @Input() isHeaderSource = false
  @Input() isControl = false
  @Input() isOffer = false
  @Input() currentPage = 1
  @Input() limit = 10
  @Input() totalItems: number
  @Output() selectedProposals = new EventEmitter<IProposal[]>()
  @Output() newPage = new EventEmitter<number>()

  proposalStatus: IProposalStatus[]
  isExternalUser = false
  environment = environment
  maxPages: number

  tableHeaders: ITableHeader[] = [
    { prop: 'orderOwner', name: 'Created by', width: 100 },
    { prop: 'integrator', name: 'Integrator', width: 100 },
    { prop: 'orderNumber', name: 'Order', width: 65 },
    { prop: 'orderTitle', name: 'Title', width: 100 },
    { prop: 'customInvoiceCode', name: 'Invoice Number', width: 100 },
    { prop: 'parsedDate', name: 'Date', width: 105 },
    { prop: 'value', name: 'Value', width: 120 },
    { prop: 'ownerParent', name: 'Responsible', width: 100 },
    { prop: 'invoiceTo', name: 'Invoice To', width: 100 },
    { prop: 'leftDays', name: 'Left', width: 65 },
    { prop: 'status', name: 'Status', width: 110 },
    { prop: 'paymentMethod', name: 'Payment Method', width: 80 },
    { prop: 'actions', name: 'Actions', width: 80 },
  ]

  tableIcons: ITableActionIcon[] = [
    {
      icon: 'visibility',
      value: 'visibility',
      tooltip: 'Details',
    },
  ]

  tableBadges: ITableBadge[] = [
    {
      prop: 'status',
      values: [
        {
          value: 'CREATED',
          class: 'custom-badge created',
        },
        {
          value: 'IN_APPROVAL',
          class: 'custom-badge in-approval',
        },
        {
          value: 'IN_PROCESSING',
          class: 'custom-badge in-processing',
        },
        {
          value: 'IN_ASSEMBLE',
          class: 'custom-badge in-assemble',
        },
        {
          value: 'INVOICE',
          class: 'custom-badge invoice',
        },
        {
          value: 'IN_DELIVERY',
          class: 'custom-badge in-delivery',
        },
        {
          value: 'DELIVERED',
          class: 'custom-badge delivered',
        },
        {
          value: 'LOSS',
          class: 'custom-badge loss',
        },
      ],
    },
  ]

  tableConfig = {
    offset: 1,
    count: 100,
  }

  constructor(
    private utils: Utils,
    private store: Store,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.proposalStatus = this.orderService.proposalStatus
    const userRoles = this.store.selectSnapshot(UserMetaState.getUserRoles)
    this.isExternalUser = this.utils.isExternalUser(userRoles)
    this.filterTableHeaders()
    if (!this.isControl) {
      this.tableHeaders = this.tableHeaders.filter(function (obj) {
        return obj.prop !== 'leftDays'
      })
    }
  }

  ngOnChanges(): void {
    this.maxPages = Math.ceil(this.totalItems / this.limit)
  }

  filterTableHeaders() {
    if (this.isControl) {
      this.tableHeaders = this.tableHeaders.filter(function (obj) {
        return obj.prop !== 'customInvoiceCode'
      })
    }

    if (!this.isControl) {
      this.tableHeaders = this.tableHeaders.filter(function (obj) {
        return obj.prop !== 'leftDays'
      })
    }

    if (!this.isOffer) {
      const columnsToRemove = ['integrator', 'orderTitle']
      columnsToRemove.forEach((column) => {
        this.tableHeaders = this.tableHeaders.filter(function (obj) {
          return obj.prop !== column
        })
      })
    }

    if (this.isOffer) {
      this.tableHeaders = this.tableHeaders.filter(function (obj) {
        return obj.prop !== 'invoiceTo'
      })
    }
  }

  drop(event: CdkDragDrop<IProposal[]>) {
    moveItemInArray(this.proposals, event.previousIndex, event.currentIndex)
  }

  onTableAction(event: ITableAction) {
    if (event.actions.includes('visibility')) {
      if (!event.data[0].paymentMethod) {
        this.utils.navigateTo(`/proposal/checkout/${event.data[0].orderId}`)
      } else {
        this.utils.navigateTo(
          `/proposal/proposal-details/${event.data[0].orderId}`,
        )
      }
    }
  }

  goToProposalDetail(proposal: IProposal): void {
    this.selectedProposals.emit([proposal])
    this.store.dispatch(new ResetQuoteStateAction())
    if (!proposal.paymentMethod) {
      this.utils.navigateTo(`/proposal/checkout/${proposal.id}`)
    } else {
      this.utils.navigateTo(`/proposal/proposal-details/${proposal.id}`)
    }
  }

  getStatusTitle(status: string): string {
    return this.proposalStatus.find((item) => item.value === status).title
  }

  getStatusClass(status: string): string {
    return this.proposalStatus.find((item) => item.value === status).class
  }

  parsedUpdateType(type: string) {
    if (!type) {
      return ''
    }
    return type.replace(/_/g, ' ')
  }

  changePage(page: number) {
    this.newPage.emit(page)
  }

  prevPage() {
    const page = this.currentPage - 1
    this.changePage(page)
  }

  nextPage() {
    const page = this.currentPage + 1
    this.changePage(page)
  }
}
